<template>
  <div class="container">
    <el-form :inline="true" :model="searchForm" ref="searchForm" class="form-container">
      <el-form-item prop="product" label="选择产品">
        <el-select size="small" v-model="searchForm.product" placeholder="选择产品">
          <el-option v-for="v in productList" :key="v.id" :label="v.name" :value="v.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="funcModule" label="功能模块">
        <el-cascader
            :options="funcModuleList"
            :props="{multiple: true,expandTrigger: 'hover',value: 'id',label: 'name',children: 'secondList'}"
            :show-all-levels="false"
            ref="cascaderModule"
            v-model="searchForm.funcModule"
            @change="cascaderChangeModule"
            collapse-tags
            placeholder="全部"
            clearable></el-cascader>
      </el-form-item>
      <el-form-item class="date-picker" prop="searchTime" label="选择时间">
        <el-date-picker
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          v-model="searchForm.searchTime">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="机构名称" prop="orgName">
        <el-input v-model="searchForm.orgName" placeholder="请输入机构名称"></el-input>
      </el-form-item>
      <el-form-item label="线索信息" prop="keyword">
        <el-input v-model="searchForm.keyword" placeholder="请输入学员姓名/联系信息"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="search-btn submit-btn" size="small" type="primary" @click="submitForm">搜索</el-button>
        <el-button class="search-btn reset-btn" size="small" @click="resetForm('searchForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="flow-table">
      <el-table
        ref="table"
        :data="tableData"
        v-loading="tableLoading"
        highlight-current-row
        :max-height="tableHeight"
        style="width: 100%">
          <el-table-column
            type="index"
            label="序号"
            align="center"
            width="100">
          </el-table-column>
          <el-table-column
            label="线索信息"
            header-align="left"
            min-width="15%">
            <template slot-scope="scope">
              <div class="user-info">
                <div class="user-img">
                  <img v-if="scope.row.headerImg" :src="scope.row.headerImg" alt="">
                  <img v-else src="../assets/none-img.jpg" alt="">
                </div>
                <div class="user-info-r">
                  <p>{{ scope.row.name || '-' }}</p>
                  <p>{{ scope.row.phoneType || '' }}</p>
                  <p>{{ tools.securityPhone(scope.row.phone) }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="机构名称"
            header-align="left"
            min-width="30%">
            <template slot-scope="scope">
              <div class="org-info">
                <div class="org-img">
                  <img v-if="scope.row.orgLogo" :src="scope.row.orgLogo" alt="">
                  <img v-else src="../assets/none-img.jpg" alt="">
                </div>
                <div class="org-info-r">
                  <div>
                    <p class="ellipsis-1">{{ scope.row.orgName }}</p>
                    <el-popover
                      :close-delay="50"
                      trigger="hover"
                      popper-class="pop-layer"
                      v-if="scope.row.remindList && scope.row.remindList.length > 0"
                    >
                      <div class="wx-bind">
                        <p>微信绑定</p>
                        <dl v-for="(v,i) in scope.row.remindList" :key="i">
                          <dd>
                            <img v-if="v.headerImg" :src="v.headerImg" alt="">
                            <img v-else src="../assets/none-img.jpg" alt="">
                            <p>{{ v.nickname || '-' }}</p>
                          </dd>
                        </dl>
                      </div>
                      <img slot="reference" class="wx-icon" src="../assets/wx1.svg" alt="">
                    </el-popover>
                  </div>
                  <p class="ellipsis-1">{{ scope.row.appName || '-' }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="产品端"
            align="center"
            min-width="15%">
            <template slot-scope="scope">
              <div class="product">
                <p>{{ scope.row.productName }}</p>
                <el-popover
                  :close-delay="50"
                  trigger="hover"
                  popper-class="pop-layer"
                  v-if="scope.row.qrcodeOrUrl"
                >
                  <div :class="scope.row.type == '1' || scope.row.type == '3'?'qrcode-box':'layer-link'">
                    <p v-if="scope.row.type == '2'">官网链接：</p>
                    <a v-if="scope.row.type == '2'" :href="scope.row.qrcodeOrUrl" target="_blank">{{ scope.row.qrcodeOrUrl }}</a>
                    <img :src="scope.row.qrcodeOrUrl" v-if="scope.row.type == '1' || scope.row.type == '3'" alt="">
                  </div>
                  <img slot="reference" v-if="scope.row.type == '1'" src="../assets/wx2.svg" alt="">
                  <img slot="reference" v-if="scope.row.type == '2'" src="../assets/link.svg" alt="">
                  <img slot="reference" v-if="scope.row.type == '3'" src="../assets/douyin.svg" alt="">
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="功能模块"
            align="center"
            min-width="15%">
            <template slot-scope="scope">
              <div class="func-module">
                <p>{{ scope.row.source }}</p>
                <el-popover
                  :close-delay="50"
                  trigger="hover"
                  popper-class="module-pop-layer"
                  @show="showQrcode(scope.row)"
                  v-if="scope.row.sourceUrlList == '1'"
                >
                  <div :class="scope.row.type == '1' || scope.row.type == '3'?'qrcode-box':'layer-link'" v-if="scope.row.moduleUrl">
                    <p>{{ scope.row.moduleUrl.title }}</p>
                    <a v-if="scope.row.type == '2' && scope.row.moduleUrl.url" :href="scope.row.moduleUrl.url" target="_blank">{{ scope.row.moduleUrl.url }}</a>
                    <img :src="scope.row.moduleUrl.url" v-if="(scope.row.type == '1' || scope.row.type == '3') && scope.row.moduleUrl.url != '-1'" alt="">
                  </div>
                  <div v-if="!scope.row.moduleUrl" class="qrcode-box-none">
                    <div>
                      <i class="el-icon-loading"></i>
                    </div>
                  </div>
                  <img slot="reference" v-if="scope.row.type == '1'" src="../assets/wx2.svg" alt="">
                  <img slot="reference" v-if="scope.row.type == '2'" src="../assets/link.svg" alt="">
                  <img slot="reference" v-if="scope.row.type == '3'" src="../assets/douyin.svg" alt="">
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="时间"
            align="center"
            min-width="15%">
            <template slot-scope="scope">
              <p class="flow-time">{{ scope.row.createDate }}</p>
            </template>
          </el-table-column>
      </el-table>
    </div>
    <div class="pagination-box">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="searchForm.currentPage"
        :page-sizes="[10, 15, 20, 25]"
        :page-size="searchForm.pageSize"
        layout="slot, prev, pager, next, jumper"
        :total="total">
        <span class="el-pagination__total">共{{total}}条记录</span>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui'
export default {
  name: "Home",
  props: {},
  data() {
    return {
      tableData: [],
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
      },
      total: 0,
      funcModuleList: [],
      productList: [],
      searchForm: {
        product: "",
        funcModule: [],
        searchTime: [],
        orgName: "",
        keyword: "",
        currentPage: 1,
        pageSize: 50,
      },
      funcModuleVal: [],
      tableLoading: false,
      tableHeight: document.body.clientHeight - 300,
      sourceId:null,
    };
  },
  computed: {},
  watch: {},
  created() {
    let that = this
    that.getSearchData()
  },
  mounted() {
    let that = this
    const today = new Date();  // 当前日期
    const year = today.getFullYear();
    const month = today.getMonth();  // 注意月份是从0开始的
    const day = today.getDate();
    let lastMonthToday;
    if (month === 0) {  // 如果是1月份
      lastMonthToday = new Date(year - 1, 11, day);  // 上个月应该是去年的12月份
    } else {
      lastMonthToday = new Date(year, month - 1, day);
    }
    that.searchForm.searchTime = [that.tools.getTimeFormat(lastMonthToday),that.tools.getTimeFormat(new Date())];
    // 获取URL中的查询参数
    const urlParams = new URLSearchParams(window.location.search);
    let uid = urlParams.get('id');
    let uname = urlParams.get('name');
    let usourceId = urlParams.get('sourceId');
    let ustartDate = urlParams.get('startDate');
    let uendDate = urlParams.get('endDate');
    console.log('获取到的参数值:', uid+','+uname+','+usourceId+','+ustartDate+','+uendDate);
    if(uid){
      that.searchForm.orgName = uname;
      that.searchForm.searchTime[0] = ustartDate?ustartDate:'';
      that.searchForm.searchTime[1] = uendDate?uendDate:'';
      that.sourceId = usourceId;
      that.funcModuleVal = usourceId&&usourceId!=0?usourceId.split(',').map(id => id.trim()):[];
    }
    that.getTableData()
    that.$nextTick(()=>{
      that.tableHeight = document.body.clientHeight - document.querySelector('.form-container').clientHeight - document.querySelector('.pagination-box').clientHeight - 72
    });
  },
  methods: {
    // 默认选中当前
    getLeafNodesWithParents(nodes, path = [],sid) {
      if(sid==null||sid==''){
        return;
      }
      for (const node of nodes) {
        if (node.id==sid) {
          this.searchForm.funcModule.push([...path, node.id]);
        }
        if (node.secondList) {
          this.getLeafNodesWithParents(node.secondList, [...path, node.id],sid);
        }
      }
    },
    // 默认选中所有下级
    getLeafNodesWithParentsOne(nodes, path = []) {
      if(this.sourceId==null||this.sourceId==''){
        return;
      }
      for (const node of nodes) {
        if (node.secondList) {
          this.getLeafNodesWithParentsOne(node.secondList, [...path, node.id]);
        } else {
          if (path.includes(this.sourceId)) {
            this.searchForm.funcModule.push([...path, node.id]);
          }
        }
      }
    },
    handleCurrentChange(val) {
      this.searchForm.currentPage = val;
      this.getTableData()
    },
    cascaderChangeModule(val){
      let that = this
      that.funcModuleVal = []
      if(val.length > 0){
        that.$refs.cascaderModule.getCheckedNodes().forEach(v=>{
          if(v.parent && !v.parent.checked || v.level == 1 && v.value){
            that.funcModuleVal.push(v.value)
          }
        })
      }
    },
    // 筛选项重置
    resetForm(formName) {
      this.funcModuleVal = []
      const today = new Date();  // 当前日期
      const year = today.getFullYear();
      const month = today.getMonth();  // 注意月份是从0开始的
      const day = today.getDate();
      let lastMonthToday;
      if (month === 0) {  // 如果是1月份
        lastMonthToday = new Date(year - 1, 11, day);  // 上个月应该是去年的12月份
      } else {
        lastMonthToday = new Date(year, month - 1, day);
      }
      this.$refs[formName].resetFields();
      this.searchForm.searchTime = [this.tools.getTimeFormat(lastMonthToday),this.tools.getTimeFormat(new Date())];
      this.getTableData();
    },
    // 查询按钮
    submitForm(){
      this.searchForm.currentPage = 1;
      this.getTableData();
    },
    // 获取搜索条件
    getSearchData(){
      let that = this
      that.getData('clewSearchData').then(res=>{
        if(res.success && res.content){
          that.productList = res.content.productList
          that.funcModuleList = that.tools.handleTreeList(res.content.moduleList,'secondList') 
          if(that.sourceId){
            if(that.sourceId.indexOf(',')>-1){
              that.sourceId.split(',').forEach(id=>{
                that.getLeafNodesWithParents(that.funcModuleList,[],id);
              })
            }else{
              that.getLeafNodesWithParentsOne(that.funcModuleList,[]);
            }
          }
        }
      }).catch(error=>{
        console.log(error)
      })
    },
    getTableData(){
      let that = this,sdt = '',edt = '',str = ''
      if(that.searchForm.searchTime && that.searchForm.searchTime.length > 0){
        sdt = that.searchForm.searchTime[0]
        edt = that.searchForm.searchTime[1]
      }
      let params = {
        start: that.searchForm.currentPage> 1?(that.searchForm.currentPage - 1) * that.searchForm.pageSize:0,
        limit: that.searchForm.pageSize,
        productId: that.searchForm.product,
        sourceId: that.funcModuleVal.length > 0 ? that.funcModuleVal.join(',') : '',
        startDate: sdt,
        endDate: edt,
        name: that.searchForm.orgName,
        clewinfo: that.searchForm.keyword
      }
      str = that.tools.objectToQueryString(params)
      params.sign = that.md5(str)
      that.tableLoading = true
      that.getData('clewList',('?' + that.tools.objectToQueryString(params))).then(res=>{
        that.tableLoading = false
        if(res.data){
          that.total = parseInt(res.recordsTotal)
          that.tableData = res.data
          that.tableData.forEach(v=>{
            that.$set(v,'moduleUrl',null)
            if(v.type == '2'){
              if(v.qrcodeOrUrl && v.qrcodeOrUrl.indexOf('http') < 0){
                v.qrcodeOrUrl = 'http://' + v.qrcodeOrUrl
              }
            }else{
              if(v.qrcodeOrUrl == '1.0.0' || !v.qrcodeOrUrl){
                v.qrcodeOrUrl = 'https://mgr.seerschool.com/static/img/empty_QRcode.49491aef.png'
              }
            }
          })
        }else{
          Message({
            message: res.msg || '数据请求失败，请联系管理员或稍后再试',
            type: 'error',
            showClose: true,
            duration: 3 * 1000
          })
        }
        
      }).catch(error=>{
        that.tableLoading = false
        console.log(error)
      })
    },
    // 获取功能模块二维码
    showQrcode(row){
      let that = this,
          params = {
            id:row.id
          }
      if(!row.moduleUrl){
        that.getData('clewModuleQRCode',('?' + that.tools.objectToQueryString(params))).then(res=>{
          if(res.success && res.code == '1' && res.content && res.content.length){
            row.moduleUrl = res.content[0]
            if(row.type == '2' && row.moduleUrl.url){
              if(row.moduleUrl.url.indexOf('http') < 0 && row.moduleUrl.url != '1.0.0'){
                row.moduleUrl.url = '//' + row.moduleUrl.url
              }
            }else{
              if((row.moduleUrl.url == '1.0.0' || !row.moduleUrl.url) && row.moduleUrl.url != "-1"){
                row.moduleUrl.url = 'https://mgr.seerschool.com/static/img/empty_QRcode.49491aef.png'
              }
            }
          }else{
            if(row.qrcodeOrUrl == '1.0.0' || !row.qrcodeOrUrl){
                v.qrcodeOrUrl = 'https://mgr.seerschool.com/static/img/empty_QRcode.49491aef.png'
            }else{
              row.moduleUrl = {
                title: '仅授权手机号',
                url: '-1'
              }
            }
            if(row.type == '2'){
              row.moduleUrl = {
                title: '暂无内容',
                url: ''
              }
            }
          }
          console.log(res,'22222')
        }).catch(error=>{
          console.log(error)
        })
      }
    }
  },
  destroyed() {},
};
</script>
<style lang="scss">
@import "../style/index.scss";
</style>
